import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const Header = ({ siteTitle }) => (
  <header
    style={{
      marginBottom: `1.45rem`,
      borderBottomStyle: `double`
    }}
  >
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 960,
      }}
    >
    <Link
          to="/">
    <StaticImage
      style={{
        float: `right`,
        top:`-2em`
      }}
      src="../images/pycograph-logo.png"
      alt="Pycograph logo"
    /></Link>
      <h1 
      style={{ 
        margin: 0,
        padding: `2.4rem 1.0875rem`,
       }}>
        <Link
          to="/"
          style={{
            color: `white`,
            textDecoration: `none`,
          }}
        >
          {siteTitle}
        </Link>
      </h1>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
