import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
import ExamplesLink from "../components/examples-link";
import SampleImg from "../images/examples/docker_compose_integration_tests_overview.png";
import SEO from "../components/seo";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Pycograph" mdxType="SEO" />
    <h2>{`Explore your Python code with graph queries`}</h2>
    <img src={SampleImg} alt="Docker Compose integration tests overview" />
    <p>{`Pycograph creates a `}<a parentName="p" {...{
        "href": "https://oss.redislabs.com/redisgraph/"
      }}>{`RedisGraph`}</a>{` model of your Python code. You can: `}</p>
    <ul>
      <li parentName="ul">{`query it with `}<a parentName="li" {...{
          "href": "https://oss.redislabs.com/redisgraph/commands/"
        }}>{`Cypher`}</a></li>
      <li parentName="ul">{`visualize it with `}<a parentName="li" {...{
          "href": "https://redislabs.com/redis-enterprise/redis-insight/"
        }}>{`RedisInsight`}</a></li>
    </ul>
    <ExamplesLink mdxType="ExamplesLink" />
    <h2>{`Getting Started`}</h2>
    <p>{`Install Pycograph from PyPI:`}</p>
    <pre><code parentName="pre" {...{}}>{`pip install pycograph
`}</code></pre>
    <p>{`Start RedisGraph and RedisInsight. E.g. via Docker containers:`}</p>
    <pre><code parentName="pre" {...{}}>{`docker run -d -p 6379:6379 redislabs/redismod
docker run -d -v redisinsight:/db -p 8001:8001 redislabs/redisinsight:latest
`}</code></pre>
    <p>{`Load your project's code with the `}<inlineCode parentName="p">{`pycograph load`}</inlineCode>{` command:`}</p>
    <pre><code parentName="pre" {...{}}>{`pycograph load --project-dir ~/code/docker/compose
`}</code></pre>
    <ExamplesLink mdxType="ExamplesLink" />
    <h2>{`RedisGraph Model`}</h2>
    <p><a parentName="p" {...{
        "href": "https://oss.redislabs.com/redisgraph/"
      }}>{`RedisGraph`}</a>{` is based on the `}<a parentName="p" {...{
        "href": "https://github.com/opencypher/openCypher/blob/master/docs/property-graph-model.adoc"
      }}>{`Property Graph Model`}</a>{` .`}</p>
    <p>{`Pycograph's current model:`}</p>
    <h3>{`Node labels`}</h3>
    <ul>
      <li parentName="ul">{`package`}</li>
      <li parentName="ul">{`module`}</li>
      <li parentName="ul">{`class`}</li>
      <li parentName="ul">{`function`}</li>
      <li parentName="ul">{`constant`}</li>
      <li parentName="ul">{`init`}</li>
      <li parentName="ul">{`test_package`}</li>
      <li parentName="ul">{`test_module`}</li>
      <li parentName="ul">{`test_function`}</li>
      <li parentName="ul">{`test_helper_function`}</li>
      <li parentName="ul">{`test_constant`}</li>
    </ul>
    <p>{`In the current model, each node has exactly one label.`}</p>
    <h3>{`Properties for all nodes`}</h3>
    <ul>
      <li parentName="ul">{`name`}</li>
      <li parentName="ul">{`full_name`}</li>
      <li parentName="ul">{`is_test_object`}</li>
      <li parentName="ul">{`test_type`}</li>
    </ul>
    <h3>{`Relationship types`}</h3>
    <h4>{`contains`}</h4>
    <p>{`No properties.`}</p>
    <h4>{`imports`}</h4>
    <p>{`Properties:`}</p>
    <ul>
      <li parentName="ul">{`from`}</li>
      <li parentName="ul">{`name`}</li>
      <li parentName="ul">{`as_name`}</li>
      <li parentName="ul">{`reference_type: absolute / relative`}</li>
      <li parentName="ul">{`level`}</li>
    </ul>
    <h4>{`calls`}</h4>
    <p>{`Properties:`}</p>
    <ul>
      <li parentName="ul">{`reference_name`}</li>
      <li parentName="ul">{`called_attribute`}</li>
    </ul>
    <ExamplesLink mdxType="ExamplesLink" />
    <h2>{`How Does It Work?`}</h2>
    <p>{`You can find the source code on `}<a parentName="p" {...{
        "href": "https://github.com/reka/pycograph"
      }}>{`GitHub`}</a>{`.`}</p>
    <h3>{`Libraries used:`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://docs.python.org/3/library/ast.html"
        }}>{`ast`}</a>{` module of the Python standard library for the abstract syntax tree`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://pydantic-docs.helpmanual.io"
        }}>{`Pydantic`}</a>{` both for the models of the intermediate objects and for the settings`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/RedisGraph/redisgraph-py"
        }}>{`redisgraph-py`}</a>{` for creating the RedisGraph model`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://typer.tiangolo.com/"
        }}>{`typer`}</a>{` for the command line interface`}</li>
    </ul>
    <h2>{`Limitations`}</h2>
    <p>{`Pycograph is in beta version.`}</p>
    <p>{`It creates a basic model with focus on the relationships betweeen the different parts of the code base. Even that model might be incomplete, ignoring some less common syntax. The goal is to provide some useful insight, not to create an exhaustive model.`}</p>
    <p>{`If Pycograph finds a syntax error, it skips the module containing the syntax error and tries to build a model from the rest of the code base.`}</p>
    <p>{`Below are some of the limitations. If you bump into other limitations, please open a GitHub issue.`}</p>
    <h3>{`Imports`}</h3>
    <p>{`The following imports will be ignored by Pycograph:`}</p>
    <ul>
      <li parentName="ul">{`imported external packages`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`import *`}</inlineCode>{` syntax`}</li>
      <li parentName="ul">{`variables`}</li>
      <li parentName="ul">{`globals`}</li>
    </ul>
    <h3>{`Calls`}</h3>
    <ul>
      <li parentName="ul">{`All the limitations of the imports.`}</li>
      <li parentName="ul">{`Resolving calls of more than 2 levels.`}</li>
    </ul>
    <h3>{`Other Known Limitations`}</h3>
    <ul>
      <li parentName="ul">{`No support for `}<inlineCode parentName="li">{`.py`}</inlineCode>{` files containing Jinja templates (e.g. cookiecutter)`}</li>
    </ul>
    <h2>{`Caveats`}</h2>
    <p>{`Pycograph is a tool for exploration. Its goal is to provide insight, not to take decisions. It shouldn't trigger automated code changes or cleanup.`}</p>
    <h2>{`Disclaimer`}</h2>
    <p>{`see `}<a parentName="p" {...{
        "href": "https://github.com/reka/pycograph/blob/main/LICENSE"
      }}>{`LICENSE`}</a></p>
    <h2>{`Many Thanks To`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/docker/compose"
        }}>{`Docker Compose`}</a></li>
      <li parentName="ul">{`Ajeet S. Raina a.k.a. `}<a parentName="li" {...{
          "href": "https://collabnix.com/"
        }}>{`Collabnix`}</a></li>
      <li parentName="ul">{`The libraries listed above :-)`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      